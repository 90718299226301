import {Injectable} from '@angular/core';
import {BaseCrudService} from './base-crud.service';
import {Content} from 'src/app/models/global/content.model';

@Injectable({
  providedIn: 'root'
})
export class ContentService extends BaseCrudService<Content> {
  constructor() {
    super('content');
  }

  approve(contentID: string) {
    return this.http.put<void>(this.baseUrl + contentID + '/approve/', {});
  }

  editRequest(contentID: string, reason?: string) {
    return this.http.put<void>(this.baseUrl + contentID + '/editRequest/', reason);
  }

  advertise(contentID: string, params: {
    internalPwc: boolean,
    corporateRoleIDs: string[],
    geographies: string[],
    filterByInterest: Boolean,
  }) {
    return this.http.post<void>(this.baseUrl + contentID + '/advertise/', params);
  }
}
