import { Injectable } from '@angular/core';
import {BaseCrudService} from 'src/app/services/crud/base-crud.service';
import {InformationRequest, NewInformationRequest} from 'src/app/models/global/information-request.model';

@Injectable({
  providedIn: 'root'
})
export class InformationRequestService extends BaseCrudService<InformationRequest> {

  constructor() {
    super('information-request');
  }

  newRequest(contentID: string, informationRequest: NewInformationRequest) {
    return this.http.post<string>(this.baseUrl + `new/${contentID}/`, informationRequest);
  }

  takeInCharge(requestID: string, actualDate: Date){
    return this.http.post<void>(this.baseUrl + `${requestID}/take-in-charge/`, actualDate);
  }

  complete(requestID: string){
    return this.http.post<void>(this.baseUrl + `${requestID}/complete/`, {});
  }

  leaveFeedback(requestID: string, feedback: string){
    return this.http.post<void>(this.baseUrl + `${requestID}/feedback/`, feedback);
  }

  leaveFeedbackOTP(otp: string, feedback: string){
    return this.http.post<void>(this.baseUrl + `otp/${otp}/feedback/`, feedback);
  }
}
