import {ResolveFn} from '@angular/router';
import {inject} from '@angular/core';
import {InformationRequest} from 'src/app/models/global/information-request.model';
import {InformationRequestService} from 'src/app/services/crud/information-request.service';
import {PageWrapper} from '@nv/core';
import {AuthService} from "src/app/services/auth.service";

export const requestResolver: ResolveFn<PageWrapper<InformationRequest>> = (route, state) => {
  let authService = inject(AuthService);
  if(authService.isBackoffice()) return undefined;
  return inject(InformationRequestService).findAll({ sortColumn: 'createdDate', sortDirection: 'desc' });
};

export const requestResolverBO: ResolveFn<PageWrapper<InformationRequest>> = (route, state) => {
  return inject(InformationRequestService).findAll({ sortColumn: 'createdDate', sortDirection: 'desc' });
};
