/// <reference types="@angular/localize" />

import { enableProdMode, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { ButtonModule } from '@appkit4/angular-components/button';
import { ModalModule } from '@appkit4/angular-components/modal';
import { TagModule } from '@appkit4/angular-components/tag';
import { CheckboxModule } from '@appkit4/angular-components/checkbox';
import { BadgeModule } from '@appkit4/angular-components/badge';
import { DropdownModule } from '@appkit4/angular-components/dropdown';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { NVCoreModule } from '@nv/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { AuthService } from './app/services/auth.service';
import { mainConfigs } from './app/configs/main-configs';
import { NVAuthConfigurations, NVAuthService } from '@nv/auth';
import { InternationalizationService, InternationalizationModule } from '@nv/internationalization';
import { HttpResponseInterceptorService } from './app/interceptors/http-response-interceptor';
import { HttpRequestInterceptorService } from './app/interceptors/http-request-interceptor';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { DecimalPipe, DatePipe, CommonModule, registerLocaleData } from '@angular/common';
import { EventsService } from './app/services/global/events.service';
import { RoutingStateService } from './app/services/routing-state.service';
import {
  InMemoryScrollingFeature,
  InMemoryScrollingOptions,
  provideRouter,
  withComponentInputBinding,
  withInMemoryScrolling,
} from '@angular/router';
import { APP_ROUTES } from "./app/app.routes";
import localeEN from '@angular/common/locales/en';
import localeIT from '@angular/common/locales/it';
import { TranslateModule } from "@ngx-translate/core";
import { MessageResolver } from "./app/resolvers/message.resolver";
import { NgbDateAdapter, NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import { NgbStringAdapter } from "./app/filters/ngbStringAdapter";
import { NgbDateCustomParserFormatter } from "./app/filters/ngbDateCustomParserFormatter";
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { provideNgxWebstorage } from 'ngx-webstorage';

registerLocaleData(localeIT, 'it_IT');
registerLocaleData(localeEN, 'en_US');

if (environment.production) {
  enableProdMode();
}

const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'top',
};

const inMemoryScrollingFeature: InMemoryScrollingFeature =
  withInMemoryScrolling(scrollConfig);

bootstrapApplication(AppComponent, {
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom(
      BrowserModule,
      NVCoreModule.forRoot(),
      LoadingBarRouterModule,
      LoadingBarModule,
      CommonModule,
      TranslateModule,
      InternationalizationModule.forRoot(environment.internationalizationUrl, environment.defLang),
      DropdownModule,
      BadgeModule,
      CheckboxModule,
      TagModule,
      ModalModule,
      ButtonModule,
    ),
    provideNgxWebstorage(),
    provideRouter(APP_ROUTES, withComponentInputBinding(), inMemoryScrollingFeature),
    RoutingStateService,
    EventsService,
    DecimalPipe,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpResponseInterceptorService,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useFactory: (internationalizationService: InternationalizationService) => internationalizationService.getCurrentLanguage(),
      deps: [InternationalizationService]
    },
    { provide: NVAuthConfigurations, useFactory: () => new NVAuthConfigurations(mainConfigs.authConfiguration) },
    {
      provide: NVAuthService,
      useClass: AuthService,
    },
    MessageResolver,
    { provide: NgbDateAdapter, useClass: NgbStringAdapter },
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
    provideAnimations(), provideCharts(withDefaultRegisterables()),
  ]
})
  .catch(err => console.error(err));
