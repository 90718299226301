/**
 * Project theme color.
 */
export enum ProjectThemeColor {
  BLUE = 'blue',
  TEAL = 'teal',
  PINK = 'pink',
  ORANGE = 'orange',
  RED = 'red',
}

/**
 * Obtain a ProjectThemeColor from a string.
 * @param color a string for color.
 */
export function getProjectThemeColorFromString(color: string): ProjectThemeColor {
  switch (color) {
    case 'blue':
      return ProjectThemeColor.BLUE;
    case 'teal':
      return ProjectThemeColor.TEAL;
    case 'pink':
      return ProjectThemeColor.PINK;
    case 'orange':
      return ProjectThemeColor.ORANGE;
    case 'red':
      return ProjectThemeColor.RED;
    default:
      return null;
  }
}

/**
 * Project theme mode.
 */
export enum ProjectThemeMode {
  LIGHT = 'light',
  DARK = 'dark',
}

/**
 * Obtain a ProjectThemeMode from a string.
 * @param mode a string for mode.
 */
export function getProjectThemeModeFromString(mode: string): ProjectThemeMode {
  switch (mode) {
    case 'light':
      return ProjectThemeMode.LIGHT;
    case 'dark':
      return ProjectThemeMode.DARK;
    default:
      return null;
  }
}

