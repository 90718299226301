import {Injectable, OnDestroy} from '@angular/core';
import {Router, RoutesRecognized} from '@angular/router';
import {filter} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RoutingStateService implements OnDestroy {
  private history: string[] = [];

  constructor(private router: Router) {
  }

  public loadRouting(): void {
    this.router.events.pipe(
      filter(event => event instanceof RoutesRecognized),
    ).subscribe((event: any) => {
      this.history.push(event.urlAfterRedirects);
    });
  }

  public getHistory(): string[] {
    return this.history;
  }

  public getPreviousUrl(): string {
    return this.history.pop() || '/';
  }

  public goBack() {
    this.router.navigate([this.history.pop()]).then(() => {
    });
  }

  ngOnDestroy() {
  }
}
