import {Injectable} from "@angular/core";
import {BaseCrudService} from "src/app/services/crud/base-crud.service";
import {ProfileNotification} from "src/app/models/global/profile-notification.model";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class NotificationService extends BaseCrudService<ProfileNotification> {
  constructor() {
    super('notification');
  }

  read(notificationIDs: string[]) {
    return this.http.put<void>(this.baseUrl + notificationIDs.join(',') + '/read', {});
  }

  unread(notificationIDs: string[]) {
    return this.http.put<void>(this.baseUrl + notificationIDs.join(',') + '/unread', {});
  }

  count(): Observable<number> {
    return this.http.get<number>(this.baseUrl + 'count');
  }

}
