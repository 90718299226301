import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';


export function toInteger(value: any): number {
  return parseInt(`${value}`, 10);
}

export function toString(value: any): string {
  return (value !== undefined && value !== null) ? `${value}` : '';
}

export function getValueInRange(value: number, max: number, min = 0): number {
  return Math.max(Math.min(value, max), min);
}

export function isString(value: any): value is string {
  return typeof value === 'string';
}

export function isNumber(value: any): value is number {
  return !isNaN(toInteger(value));
}

export function isInteger(value: any): value is number {
  return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
}

export function isDefined(value: any): boolean {
  return value !== undefined && value !== null;
}

export function padNumber(value: number) {
  if (isNumber(value)) {
    return `0${value}`.slice(-2);
  } else {
    return '';
  }
}

export function regExpEscape(text: any) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export function hasClassName(element: any, className: string): boolean {
  return element && element.className && element.className.split &&
    element.className.split(/\s+/).indexOf(className) >= 0;
}

if (typeof Element !== 'undefined' && !Element.prototype.closest) {
  // Polyfill for ie10+

  if (!Element.prototype.matches) {
    // IE uses the non-standard name: msMatchesSelector
    Element.prototype.matches = (Element.prototype as any).msMatchesSelector || Element.prototype.webkitMatchesSelector;
  }

  Element.prototype.closest = function (s: string) {
    let el = this;
    if (!document.documentElement.contains(el)) {
      return null;
    }
    do {
      if (el.matches(s)) {
        return el;
      }
      // TODO: to check
      // @ts-ignore
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}

export function closest(element: HTMLElement, selector: any): HTMLElement {
  if (!selector) {
    return null;
  }

  return element.closest(selector);
}

export function jsDate2ngbDateStruct(fromDate: Date): NgbDateStruct {
  const newDate = new Date(fromDate);
  const toYear = Number(newDate.getUTCFullYear());
  const toMonth = Number(newDate.getUTCMonth() + 1);
  const toDay = Number(newDate.getUTCDate());
  const toDate: NgbDateStruct = {
    year: toYear,
    month: toMonth,
    day: toDay
  };
  return toDate;
}

export function ngbDateStruct2jsDate(fromDate: NgbDateStruct): Date {
  return new Date(fromDate.year, fromDate.month - 1, fromDate.day);
}
