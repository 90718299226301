import { HttpClient, HttpParams } from '@angular/common/http';
import { computed, inject, Injectable, input, SecurityContext, signal } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NVAuth, NVAuthConfigurations, NVAuthService } from '@nv/auth';
import { createHash, randomBytes } from 'crypto';
import { SessionStorageService } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { mainConfigs } from 'src/app/configs/main-configs';
import { UserOpenAM } from 'src/app/models/userOpenAM';
import { Profile } from 'src/app/models/global/profile.model';
import { NVUser } from '@nv/auth/lib/data/nv-user.model';
import { Role } from 'src/app/enums/role';

@Injectable({
  providedIn: 'root',
  useExisting: NVAuthService
})
export class AuthService extends NVAuthService {
  userContext = signal<NVUser>(undefined);
  tutorialWatched = signal(false);
  currentProfile = computed(() => {
    if (this.userContext()) {
      return this.userContext()?.profile as Profile;
    }
  });
  tutorialCompleted = computed(() => {
    if (this.currentProfile()) {
      return this.currentProfile().tutorialCompleted;
    }
    return this.tutorialWatched();
  })
  rolesSet = computed(() => {
    if (this.userContext()) {
      return this.userContext()?.roles;
    }
    return [];
  });
  isBackoffice = computed(() => {
    const backofficeRoles: string[] = [Role.U_BO, Role.U_REF, Role.G_PDT];
    return this.rolesSet().some(role => backofficeRoles.includes(role));
  });
  isProductManager = computed(() => {
    const backofficeRoles: string[] = [Role.U_BO, Role.G_PDT];
    return this.rolesSet().some(role => backofficeRoles.includes(role));
  });
  isStaffUser = computed(() => {
    return this.rolesSet().includes(Role.U_ST);
  });

  private sessionStorageService = inject(SessionStorageService)
  private httpClient = inject(HttpClient)
  private domSanitizer = inject(DomSanitizer)
  /*
  constructor(
    private sessionStorageService: SessionStorageService,
    private httpClient: HttpClient,
    private domSanitizer: DomSanitizer,
    config: NVAuthConfigurations,
  ) {
    super(
      sessionStorageService,
      httpClient,
      config,
      domSanitizer
    );
  }*/

  loadContextWithPipe() {
    return this.loadContext().pipe(tap(nvAuth => {
      this.userContext.set(nvAuth.user);
    }));
  }

  private CODE_VERIFIER = 'code_verifier';

  authorize(authProvider: string) {
    //create and store code_verifier
    let codeVerifier = this.createPKCEVerifier();
    this.sessionStorageService.store(this.CODE_VERIFIER, codeVerifier);
    //create and send code challenge
    const codeChallenge = this.createPKCECodeChallenge(codeVerifier);
    //compose backend URL
    //authProvider is the oauth2 auth provider
    const url = new URL(environment.backendURL + mainConfigs.callbackurl + authProvider);
    url.search = new URLSearchParams({
      'codeChallenge': codeChallenge,
      'codeChallengeMethod': 'S256',
      'redirect_uri': environment.redirecturi
    }).toString();
    try {
      window.location.href = url.toString()
    } catch (error) {
      console.error('PKCE flow error', error);
    }
  }

  retrieveAccessToken(code: string): Observable<string | null> {
    if (code === null) return null;
    const url = environment.backendURL + mainConfigs.retrieveAccesToken;
    let params = new HttpParams();
    params = params.append('code', encodeURIComponent(code));
    params = params.append('codeVerifier', this.sessionStorageService.retrieve(this.CODE_VERIFIER));
    let options = { params, responseType: 'text' as 'text', withCredentials: true };
    return this.httpClient.get(url, options);
  }

  createPKCEVerifier(length = 48): string {
    if (length < 32 || length > 128) {
      throw new Error('Verifier length must be between 32 and 128');
    }
    const randomBuffer = randomBytes(length);
    return randomBuffer.toString('base64');
  }

  createPKCECodeChallenge(codeVerifier: string): string {
    const sha256Hash = createHash('sha256').update(codeVerifier, 'utf8').digest('base64');
    const base64UrlEncoded = sha256Hash.replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
    return base64UrlEncoded;
  }

  public getAccessToken(): string {
    return this.sessionStorageService.retrieve(mainConfigs.accessToken);
  }

  openAMGetAuthToken(applicationToken: string): Observable<any> {
    // let headers = new HttpHeaders();
    let options = { responseType: 'text' as 'text', withCredentials: true };
    // console.log(headers);
    return this.httpClient.get(environment.backendURL + mainConfigs.oauth2AccessTokenUrl, options).pipe(map(r => {
      return r;
    }));
  }

  setToken(input: string) {
    this.sessionStorageService.store(mainConfigs.authConfiguration.get('internalAuthStorageKey'), input);
  }

  getToken(): string | null {
    return this.sessionStorageService.retrieve(mainConfigs.authConfiguration.get('internalAuthStorageKey'));
  }

  loadContextWithToken(token): Observable<NVAuth> {
    this.setToken(token);
    return this.loadContextWithPipe();
  }

  login(username: string, password: string): Observable<any> {
    return super.login(username, password).pipe(tap(async nvUser => {
      this.userContext.set(nvUser);
    }));
  }

  openAMLogout() {
    const that = this;
    that.httpClient.post(environment.backendURL + mainConfigs.authConfiguration.get('internalLogoutEndpoint'), null, { responseType: 'text' }).subscribe(res => {
      this.clearToken();
      window.location.href = this.domSanitizer.sanitize(SecurityContext.URL, res);
    });
    return null;
  }

  openAMcreateNewUser(newUser: UserOpenAM, registrationToken: string): Observable<boolean> {
    return this.httpClient.post<boolean>(environment.backendURL + 'auth/sign-up/' + registrationToken + '/confirm', newUser).pipe(map(r => {
      return r;
    }));
  }

  hasRole(role: string) {
    return this.rolesSet().includes(role);
  }

  isAdmin() {
    return this.userContext().username === 'admin';
  }

  clearToken() {
    this.userContext.set(undefined);
    this.sessionStorageService.clear(mainConfigs.authConfiguration.get('internalAuthStorageKey'));
  }

  logout(): Observable<any> {
    return super.logout().pipe(tap(_ => {
      this.userContext.set(undefined);
      this.clearToken();
    }));
  }
}
