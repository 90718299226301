import { ResolveFn } from '@angular/router';
import { PageWrapper } from '@nv/core';
import {Profile, ProfileName} from 'src/app/models/global/profile.model';
import {inject} from '@angular/core';
import {ProfileService} from 'src/app/services/crud/profile.service';

export const allProfileResolver: ResolveFn<PageWrapper<Profile>> = (route, state) => {
  return inject(ProfileService).findAll();
};

export const profileResolver: ResolveFn<Profile> = (route, state) => {
  return inject(ProfileService).findOne(route.params['profileID']);
};

export const profileByRoleUBOResolver: ResolveFn<ProfileName[]> = (route, state) => {
  return inject(ProfileService).findAllByRole(['U_BO']);
};

export const profileByRoleUBAResolver: ResolveFn<ProfileName[]> = (route, state) => {
  return inject(ProfileService).findAllByRole(['U_BA']);
};

export const profileByRoleUREFResolver: ResolveFn<ProfileName[]> = (route, state) => {
  return inject(ProfileService).findAllByRole(['U_REF']);
};

export const profileByRoleUBOUREFResolver: ResolveFn<ProfileName[]> = (route, state) => {
  return inject(ProfileService).findAllByRole(['U_BO', 'U_REF']);
};
