import {Injectable} from '@angular/core';
import {BaseCrudService} from './base-crud.service';
import {Company} from 'src/app/models/global/company.model';

@Injectable({
  providedIn: 'root'
})
export class CompanyService extends BaseCrudService<Company> {

  constructor() {
    super('company');
  }
}
