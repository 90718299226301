import {ResolveFn} from '@angular/router';
import {inject} from '@angular/core';
import {ServiceContent} from 'src/app/models/global/service-content.model';
import {ServiceContentService} from 'src/app/services/crud/service-content.service';
import {PageWrapper} from '@nv/core';
import {ElementContent} from 'src/app/models/global/element-content.model';
import {ElementContentService} from 'src/app/services/crud/element-content.service';
import { Content } from 'src/app/models/global/content.model';
import {ContentService} from 'src/app/services/crud/content.service';

export const allServiceContentResolver: ResolveFn<PageWrapper<ServiceContent>> = () => {
  return inject(ServiceContentService).findAll();
};

export const allElementContentResolver: ResolveFn<PageWrapper<ElementContent>> = () => {
  return inject(ElementContentService).findAll();
};

export const serviceContentResolver: ResolveFn<ServiceContent> = (route, state) => {
  return inject(ServiceContentService).findOne(route.params['serviceID']);
};

export const elementContentResolver: ResolveFn<ElementContent> = (route, state) => {
  return inject(ElementContentService).findOne(route.params['elementID']);
};

export const allContentResolver: ResolveFn<PageWrapper<Content>> = () => {
  return inject(ContentService).findAll();
};

export const contentResolver: ResolveFn<ElementContent> = (route, state) => {
  return inject(ElementContentService).findOne(route.params['elementID']);
};
