import {Injectable} from '@angular/core';
import {BaseCrudService} from './base-crud.service';
import {CorporateRole} from 'src/app/models/global/corporate-role.model';


@Injectable({
  providedIn: 'root'
})
export class CorporateRoleService extends BaseCrudService<CorporateRole> {

  constructor() {
    super('corporate-role');
  }

  findAllByCompany() {
    return this.http.get<CorporateRole[]>(this.baseUrl + 'colleague-roles/');
  }
}
