import {CanActivateFn, Router} from '@angular/router';
import {AuthService} from 'src/app/services/auth.service';
import {SessionStorageService} from 'ngx-webstorage';
import {inject} from '@angular/core';
import {mainConfigs} from 'src/app/configs/main-configs';

export const RETURN_URL = 'return_url';

export const notLoggedGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  if (!authService.getToken()) {
    const sessionStorageService = inject(SessionStorageService);
    const router = inject(Router);
    sessionStorageService.store(RETURN_URL, state.url);
    router.navigateByUrl(mainConfigs.loginURL);
    return false;
  }
  return true;
};
