import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, viewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NVAuthConfigurations } from '@nv/auth';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { CustomNotificationService } from 'src/app/services/custom-notification.service';
import { LoaderService } from '../services/loader.service';
import { ModalContainerService } from '@appkit4/angular-components/modal';

@Injectable({
  providedIn: 'root'
})
export class HttpResponseInterceptorService implements HttpInterceptor {

  constructor(
    private notificationService: CustomNotificationService,
    private configuration: NVAuthConfigurations,
    private translateService: TranslateService,
    private authService: AuthService,
    private router: Router,
    private loaderService: LoaderService,
    private modalModule: ModalContainerService
  ) {
  }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((_: HttpEvent<any>) => { },
        (errorResponse: any) => {
          // if it's not an HttpErrorResponse instance, return
          if (!(errorResponse instanceof HttpErrorResponse)) {
            return null;
          }
          this.loaderService.hide();
          this.modalModule.close('modalLongOperation');
          if (errorResponse.error.error === 'FYSAUTH0007') {
            return throwError('Error!');
          }
          if (request.params.get('onlyValidation') === 'true') {
            return null;
          }
          if (errorResponse.status === 401) {
            if (request.url.endsWith('/auth/login') || request.url.endsWith('/auth/logout')) {
              this.authService.clearToken();
              this.router.navigateByUrl(this.configuration.loginURL);
              this.notificationService.showError(this.translateService.instant('sessione.scaduta'));
            } else {
              this.authService.logout().subscribe(_ => {
                this.router.navigateByUrl(this.configuration.loginURL);
                this.notificationService.showError(this.translateService.instant('sessione.scaduta'));
              });
            }
            return null;
          } else if (errorResponse.status === 0) {
            this.authService.clearToken();
            this.notificationService.showBackendUnreachableError();
            this.router.navigateByUrl(this.configuration.loginURL);
            return errorResponse;
          }
          if (request.params.get('onlyValidation') === 'true') {
            return null;
          }
          const err = request.responseType === 'text' ? JSON.parse(errorResponse.error) : errorResponse.error;
          // if there is an error message, manage it
          if (err) {
            if (err instanceof ProgressEvent) {
              this.router.navigateByUrl(this.configuration.loginURL);
              return;
            }
            console.error('HTTP ERROR', err);
            switch (err.error) {
              // *** TODO: error handling ***
              case 'AUTH000001':
                this.authService.logout();
                this.notificationService.showError(this.translateService.instant(err.error));
                console.warn('Unauthorized', err);
                break;
              case 'FYSVAL0000':
                this.notificationService.showError(this.translateService.instant(err.error));
                break;
              default:
                break;
            }
          } else {
            if (err.message) {
              this.notificationService.showError(this.translateService.instant(err.message));
            } else {
              this.notificationService.showError(this.translateService.instant('Unable to contact the server'));
            }
            console.warn('An error has occured', err);
          }
          return request;
        }));
  }
}
