import {inject} from '@angular/core';
import {NVCrudService} from '@nv/core';
import {HttpClient} from '@angular/common/http';
import {EventsService} from 'src/app/services/global/events.service';
import {environment} from 'src/environments/environment';
import {mainConfigs} from 'src/app/configs/main-configs';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

type Entity = {
  id?: string;
  [key: string]: any;
}

export abstract class BaseCrudService<T extends Entity> {
  nvCrudService: NVCrudService<T>;
  http = inject(HttpClient);
  eventsService = inject(EventsService);

  baseUrl: string;

  protected constructor(protected readonly resourceName: string) {
    this.baseUrl = environment.backendURL + 'api/' + resourceName + '/';
    let nestedPath = '';
    let actualResourceName = '';
    const lastSlash = resourceName.lastIndexOf('/');

    if (lastSlash > -1) {
      nestedPath = resourceName.slice(0, lastSlash + 1);
      actualResourceName = resourceName.slice(lastSlash + 1);
    } else {
      actualResourceName = resourceName;
    }

    this.nvCrudService = new class extends NVCrudService<T> {
      constructor(http: HttpClient, eventsService: EventsService) {
        super(http, environment.backendURL + 'api/' + nestedPath, actualResourceName, eventsService, mainConfigs.auditProperties);
      }
    }(this.http, this.eventsService);
  }

  findAll(params?: Partial<{
    pathVariables: Map<string, string>,
    searchTerm: string,
    terms: string[],
    sortColumn: string,
    sortDirection: string,
    page: number,
    pageSize: number,
    filter: Map<string, string | string[]>
  }>) {
    return this.nvCrudService.findAll(
      params?.pathVariables ?? new Map(), params?.searchTerm, params?.terms, params?.sortColumn,
      params?.sortDirection, params?.page, params?.pageSize ?? 10, params?.filter,
    );
  }

  listAll(params?: Partial<{
    pathVariables: Map<string, string>,
    searchTerm: string,
    terms: string[],
    sortColumn: string,
    sortDirection: string,
    filter: Map<string, string | string[]>
  }>) {
    return this.nvCrudService.listAll(
      params?.pathVariables ?? new Map(), params?.searchTerm, params?.terms,
      params?.sortColumn, params?.sortDirection, params?.filter,
    );
  }

  create(resources: T | T[], params?: Partial<{
    pathVariables: Map<string, string>,
    onlyValidation: boolean
  }>): Observable<string[]> {
    return this.nvCrudService.create(params?.pathVariables ?? new Map(), Array.isArray(resources) ? resources : [resources], params?.onlyValidation)
      .pipe(map(res => {
        if (typeof res === 'string') {
          return JSON.parse(res);
        }
        return res;
      }));
  }

  findOne(resourceID: string, pathVariables: Map<string, string> = new Map()) {
    return this.nvCrudService.findOne(pathVariables, resourceID);
  }

  update(resourceID: string, resource: T, params?: Partial<{
    pathVariables: Map<string, string>,
    onlyValidation: boolean
  }>) {
    resource.id = resourceID;
    return this.nvCrudService.update(params?.pathVariables ?? new Map(), resourceID, resource, params?.onlyValidation);
  }

  delete(resourceIDs: string[], pathVariables: Map<string, string> = new Map()) {
    return this.nvCrudService.delete(pathVariables, resourceIDs);
  }
}
