import {Injectable} from '@angular/core';
import {BaseCrudService} from './base-crud.service';
import {ElementContent} from 'src/app/models/global/element-content.model';
import {ServiceContent} from "src/app/models/global/service-content.model";

@Injectable({
  providedIn: 'root'
})
export class ElementContentService extends BaseCrudService<ElementContent> {
  constructor() {
    super('content/element');
  }

  createUsingAI(attachedDocuments: string[]) {
    return this.http.post<ServiceContent>(this.baseUrl + 'ai', {attachedDocuments: attachedDocuments});
  }
}
