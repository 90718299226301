import {BusinessUnit} from 'src/app/models/global/business-unit.model';
import {Injectable} from '@angular/core';
import {BaseCrudService} from './base-crud.service';

@Injectable({
  providedIn: 'root'
})
export class BusinessUnitService extends BaseCrudService<BusinessUnit> {

  constructor() {
    super('business-unit');
  }
}
