import {Injectable} from '@angular/core';
import {BaseCrudService} from './base-crud.service';
import {LineOfService} from 'src/app/models/global/line-of-service.model';

@Injectable({
  providedIn: 'root'
})
export class LosService extends BaseCrudService<LineOfService> {

  constructor() {
    super('line-of-service');
  }
}
