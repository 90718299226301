import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Profile, ProfileName, UpdateProfile} from 'src/app/models/global/profile.model';
import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ResetPasswordConfirmModel} from 'src/app/models/reset-password-confirm.model';
import {BaseCrudService} from './base-crud.service';
import {PageWrapper} from '@nv/core';
import {Content} from 'src/app/models/global/content.model';

@Injectable({
  providedIn: 'root'
})
export class ProfileService extends BaseCrudService<Profile> {

  constructor() {
    super('profile');
  }

  // send a vote
  resetPassword(
    pathVariables: Map<string, string>,
    profileID: string,
  ): Observable<void> {
    const extraParams: HttpParams = new HttpParams();
    return this.http
      .put<any>(this.baseUrl + profileID + '/resetPassword',
        {params: extraParams})
      .pipe(map(res => {
        return res;
      }));
  }

  // create a reset password request
  resetPasswordRequest(
    username: string,
  ): Observable<void> {
    const extraParams: HttpParams = new HttpParams();
    return this.http
      .put<any>(
        environment.backendURL + 'api/public/' + username + '/resetPasswordRequest',
        {params: extraParams})
      .pipe(map(res => {
        return res;
      }));
  }

  // confirm a reset Password
  resetPasswordConfirm(
    resetPasswordConfirm: ResetPasswordConfirmModel,
  ): Observable<void> {
    const extraParams: HttpParams = new HttpParams();
    return this.http
      .put<any>(
        environment.backendURL + 'api/public/' + resetPasswordConfirm.username + '/resetPasswordConfirm',
        resetPasswordConfirm,
        {params: extraParams})
      .pipe(map(res => {
        return res;
      }));
  }

  disable(profileIDs: string[], reasonForBlocking: string) {
    return this.http.put<void>(this.baseUrl + profileIDs.join(',') + '/disable', reasonForBlocking);
  }

  enable(profileIDs: string[]) {
    return this.http.put<void>(this.baseUrl + profileIDs.join(',') + '/enable', {});
  }

  findAllByRole(role: string[]): Observable<ProfileName[]> {
    return this.http.get<ProfileName[]>(this.baseUrl + 'roles/' + role + '/');
  }

  completeProfile(categoriesOfInterest?: string[]) {
    return this.http.put<void>(this.baseUrl + 'complete', categoriesOfInterest);
  }

  updateProfile(updatedProfile: UpdateProfile) {
    return this.http.put<void>(this.baseUrl, updatedProfile);
  }

  getColleagues(params?: Partial<{
    sortColumn: string,
    sortDirection: string,
    page: number,
    pageSize: number,
    filter: Map<string, string | string[]>
  }>): Observable<PageWrapper<ProfileName>> {
    let httpParams = new HttpParams()
      .append('page', params?.page ?? 0)
      .append('size', params?.pageSize ?? 4);
    if (params?.sortColumn) {
      const sort = params.sortColumn + ',' + params.sortDirection;
      httpParams = httpParams.append('sort', sort);
    }
    if (params?.filter) {
      for (let [key, value] of params.filter) {
        httpParams = httpParams.append(key, value as string);
      }
    }
    return this.http.get<PageWrapper<ProfileName>>(this.baseUrl + 'colleagues', {params: httpParams});
  }

  getShareList() {
    return this.http.get<ProfileName[]>(this.baseUrl + 'share-list');
  }

  addFavorite(contendID: string) {
    return this.http.put<void>(this.baseUrl + 'add-favorite/' + contendID, {});
  }

  removeFavorite(contendID: string) {
    return this.http.put<void>(this.baseUrl + 'remove-favorite/' + contendID, {});
  }

  tutorialComplete() {
    return this.http.put<void>(this.baseUrl + 'tutorial-complete', {});
  }

  sharedWithMe() {
    return this.http.get<Content[]>(this.baseUrl + 'shared-with-me/');
  }

  sharedByMe() {
    return this.http.get<Content[]>(this.baseUrl + 'shared-by-me/');
  }

  geographies() {
    return this.http.get<string[]>(this.baseUrl + 'geographies/');
  }

}
