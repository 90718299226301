import {ResolveFn} from '@angular/router';
import {inject} from '@angular/core';
import {HomeService} from 'src/app/services/crud/home.service';
import {ServiceContent} from 'src/app/models/global/service-content.model';
import {ElementContent} from 'src/app/models/global/element-content.model';

export const serviceResolver: ResolveFn<ServiceContent> = (route, state) => {
  return inject(HomeService).findService(route.params['contentId']);
};

export const elementResolver: ResolveFn<ElementContent> = (route, state) => {
  return inject(HomeService).findElement(route.params['contentId']);
};
