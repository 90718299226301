import { CanActivateFn } from '@angular/router';
import {inject} from '@angular/core';
import {AuthService} from 'src/app/services/auth.service';
import {lastValueFrom} from 'src/app/utility';

export const loadProfileGuard: CanActivateFn = async (route, state) => {
  const authService = inject(AuthService);
  if (authService.getToken()) {
    await lastValueFrom(authService.loadContextWithPipe());
  }
  return true;
};
