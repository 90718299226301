import {Injectable} from '@angular/core';
import {BaseCrudService} from './base-crud.service';
import {ServiceContent} from 'src/app/models/global/service-content.model';

@Injectable({
  providedIn: 'root'
})
export class ServiceContentService extends BaseCrudService<ServiceContent> {
  constructor() {
    super('content/service');
  }

  createUsingAI(attachedDocuments: string[]) {
    return this.http.post<ServiceContent>(this.baseUrl + 'ai', {attachedDocuments: attachedDocuments});
  }
}
