import {ApplicationRef, EnvironmentInjector, inject, Injectable,} from '@angular/core';
import {NotificationListComponent, NotificationService} from '@appkit4/angular-components/notification';
import {TranslateService} from '@ngx-translate/core';
import {AppComponent} from 'src/app/app.component';

const NOTIFICATION_ID = 'notification-id'

@Injectable({
  providedIn: 'root'
})
export class CustomNotificationService {
  appRef = inject(ApplicationRef);
  injector = inject(EnvironmentInjector);
  notificationService = inject(NotificationService);
  translateService = inject(TranslateService);
  stopNotification = false;

  constructor() {
    setTimeout(() => {
      let component = this.appRef.components[0].instance as AppComponent;
      let notificationListComponentComponentRef = component.viewContainerRef.createComponent(NotificationListComponent, {
        environmentInjector: this.injector
      });
      notificationListComponentComponentRef.instance.id = NOTIFICATION_ID;
      notificationListComponentComponentRef.instance.position = 'bottomLeft';
    })
  }

  showDefault(messageKey: string) {
    if (this.stopNotification) return;
    this.notificationService.show('', this.translateService.instant(messageKey),
      '', '', {id: NOTIFICATION_ID, duration: 5000, showClose: true});
  }

  showError(messageKey: string) {
    if (this.stopNotification) return;
    this.notificationService.show('', this.translateService.instant(messageKey),
      '', '', {id: NOTIFICATION_ID, duration: 5000, showClose: true, icon: 'icon-error-fill'});
  }

  showBackendUnreachableError() {
    if (this.stopNotification) return;
    this.notificationService.show('', 'Server unreachable!',
      '', '', {id: NOTIFICATION_ID, duration: 10000, showClose: true, icon: 'icon-error-fill'});
    this.stopNotification = true;
  }

  showWarning(messageKey: string) {
    if (this.stopNotification) return;
    this.notificationService.show('', this.translateService.instant(messageKey),
      '', '', {id: NOTIFICATION_ID, duration: 5000, showClose: true, icon: 'icon-warning-fill'});
  }

  showSuccess(messageKey: string) {
    if (this.stopNotification) return;
    this.notificationService.show('', this.translateService.instant(messageKey),
      '', '', {id: NOTIFICATION_ID, duration: 5000, showClose: true, icon: 'icon-success-fill'});
  }
}
