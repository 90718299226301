import {inject, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Content} from 'src/app/models/global/content.model';
import {ServiceContent} from 'src/app/models/global/service-content.model';
import {ElementContent} from 'src/app/models/global/element-content.model';
import {SearchResult} from 'src/app/models/global/search-result';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  http = inject(HttpClient);
  baseUrl = environment.backendURL + 'api/home/';

  suggested() {
    return this.http.get<Content[]>(this.baseUrl + 'suggested/');
  }

  news() {
    return this.http.get<Content[]>(this.baseUrl + 'news/');
  }

  recentlyViewed() {
    return this.http.get<Content[]>(this.baseUrl + 'recentlyViewed/');
  }

  findService(resourceID: string) {
    return this.http.get<ServiceContent>(this.baseUrl + 'service/' + resourceID + '/');
  }

  findElement(resourceID: string) {
    return this.http.get<ElementContent>(this.baseUrl + 'element/' + resourceID + '/');
  }

  contentAnalytics(contentType: string, counter: number) {
    return this.http.post<void>(this.baseUrl + 'analytics/', {
      contentType: contentType, counter: counter
    });
  }

  externalAccess() {
    return this.http.post<void>(this.baseUrl + 'externalAccess/', {});
  }

  findRelated(resourceID: string) {
    return this.http.get<Content[]>(this.baseUrl + 'related/' + resourceID + '/');
  }

  share(contentID: string, profileToShareWith: string[]) {
    return this.http.post<void>(this.baseUrl + contentID + '/share/', profileToShareWith);
  }

  search(query: string, facets: string[]) {
    let params: HttpParams = new HttpParams().set('q', query);
    if (facets.length > 0) {
      params = params.set('f', facets.join(','));
    }
    return this.http.get<SearchResult>(this.baseUrl + 'ai-search/', {params: params});
  }
}
