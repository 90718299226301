import {Profile, ProfileName} from 'src/app/models/global/profile.model';
import {Observable} from 'rxjs';

export function getFileName(url: string): string {
  return decodeURI(new URL(url).pathname.split('/').pop().substring(9));
}

export function getUTCDate(): Date {
  const date = new Date();
  return new Date(Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes()
  ));
}

export function isPwc(profile: Profile | ProfileName): boolean {
  return profile.company?.name.toLowerCase().includes('pwc');
}

export function hasRole(roles: string[], role: string): boolean {
  return roles.find(r => r === role) !== undefined;
}

export function groupByWindows<T>(elements: T[], windowSize: number): T[][] {
  const windows = [];
  let currentWindow = [];
  if (elements) {
    for (const element of elements) {
      currentWindow.push(element);
      if (currentWindow.length === windowSize) {
        windows.push(currentWindow);
        currentWindow = [];
      }
    }
  }
  if (currentWindow.length > 0) {
    windows.push(currentWindow);
  }
  return windows;
}

export function lastValueFrom<T>(source$: Observable<T>): Promise<T> {
  return new Promise((resolve, reject) => {
    let lastValue;
    let hasError = false;

    source$.subscribe({
      next: (value) => lastValue = value,
      error: (error) => {
        hasError = true;
        reject(error);
      },
      complete: () => {
        if (hasError) {
          return;
        }

        if (lastValue === undefined) {
          reject(new Error('Observable completed without emitting any values'));
          return;
        }

        resolve(lastValue);
      },
    });
  });
}
