import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {getProjectThemeColorFromString, ProjectThemeColor, ProjectThemeMode} from 'src/app/enums/project-theme.enum';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  constructor(@Inject(DOCUMENT) private document: Document) {
  }

  // TODO: implement a way to save the current value in the local storage

  /**
   * It changes the applied theme globally.
   * @param mode the mode to use.
   */
  public setThemeMode(mode: ProjectThemeMode) {
    this.document.body.setAttribute('data-mode', mode);
  }

  /**
   * It changes the applied theme color globally.
   * @param color the color to use.
   */
  public setThemeColor(color: ProjectThemeColor) {
    this.document.body.setAttribute('data-theme', color);
  }

  /**
   * It changes the applied theme mode globally to light.
   */
  public changeToLightMode() {
    this.document.body.setAttribute('data-mode', ProjectThemeMode.LIGHT);
  }

  /**
   * It changes the applied theme mode globally to dark.
   */
  public changeToDarkMode() {
    this.document.body.setAttribute('data-mode', ProjectThemeMode.DARK);
  }

  /**
   * Returns the actual theme color of the project.
   */
  public getActualThemeColor(): ProjectThemeColor {
    return getProjectThemeColorFromString(this.document.body.getAttribute('data-theme'));
  }
}
