import {CanMatchFn, Route, Router, UrlSegment} from '@angular/router';
import {inject} from '@angular/core';
import {AuthService} from 'src/app/services/auth.service';
import { toObservable } from '@angular/core/rxjs-interop';
import {lastValueFrom} from 'src/app/utility';
import {ProfileService} from 'src/app/services/crud/profile.service';

export const profileNotCompletesGuard: CanMatchFn = async (next: Route, segments: UrlSegment[]) => {
  const authService = inject(AuthService);
  const profileService = inject(ProfileService);
  const router = inject(Router);
  const sub = toObservable(authService.currentProfile).subscribe(profile => {
    if (!!profile) {
      if (authService.isStaffUser()){
        profileService.completeProfile().subscribe();
        return true;
      }
      if (!authService.isProductManager() && !profile.profileCompleted) {
        router.navigateByUrl('/complete-profile');
      } else {
        sub.unsubscribe();
      }
    }
  })
  return true;
}
