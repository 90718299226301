import { ResolveFn } from '@angular/router';
import {inject} from '@angular/core';
import {LosService} from 'src/app/services/crud/los.service';
import {PageWrapper} from '@nv/core';
import {LineOfService} from 'src/app/models/global/line-of-service.model';
import {CorporateRole} from 'src/app/models/global/corporate-role.model';
import {CorporateRoleService} from 'src/app/services/crud/corporate-role.service';
import {CompanyService} from 'src/app/services/crud/company.service';
import {BusinessUnit} from 'src/app/models/global/business-unit.model';
import {BusinessUnitService} from 'src/app/services/crud/business-unit.service';
import {Company} from 'src/app/models/global/company.model';

export const lineOfServiceResolver: ResolveFn<PageWrapper<LineOfService>> = (route, state) => {
  return inject(LosService).findAll();
};

export const businessUnitResolver: ResolveFn<PageWrapper<BusinessUnit>> = (route, state) => {
  return inject(BusinessUnitService).findAll();
};

export const corporateRoleResolver: ResolveFn<PageWrapper<CorporateRole>> = (route, state) => {
  return inject(CorporateRoleService).findAll();
};

export const companyResolver: ResolveFn<PageWrapper<CorporateRole>> = (route, state) => {
  return inject(CompanyService).findAll();
};

export const losListResolver: ResolveFn<LineOfService[]> = (route, state) => {
  return inject(LosService).listAll();
};

export const buListResolver: ResolveFn<BusinessUnit[]> = (route, state) => {
  return inject(BusinessUnitService).listAll();
};

export const companyListResolver: ResolveFn<Company[]> = (route, state) => {
  return inject(CompanyService).listAll();
};

export const corporateRoleListResolver: ResolveFn<CorporateRole[]> = (route, state) => {
  return inject(CorporateRoleService).listAll();
};

export const corporateRoleByCompanyResolver: ResolveFn<CorporateRole[]> = (route, state) => {
  return inject(CorporateRoleService).findAllByCompany();
};
